
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import QuickReplyEditorPopup from '@/components/CrmCustomers/QuickReplyEditorPopup.vue';
import { QuickReplyMessageViewModel } from '@/models/CrmCustomers';
import swal from 'sweetalert2';
import { api } from '@/services/Api';
import q from 'q';
import { useI18n } from 'vue-i18n';
import { QuickReplyMode } from '@/models/Enums';

export default defineComponent({
    components: {
        OverlayModal,
        QuickReplyEditorPopup
    },
    name: 'QuickReplyPopup',
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const { t } = useI18n();
        const selectedQuickReplyMode = ref(QuickReplyMode.Select);
        const modalQuickReplyEditor = ref<InstanceType<typeof QuickReplyEditorPopup>>();
        const withManage = ref(false);
        const withSelect = ref(false);
        const quickReplyMessages = ref<QuickReplyMessageViewModel[]>([]);
        async function getQuickReplyMessages() {
            swal.showLoading();
            const response = await api.getQuickReplyMessages();
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            quickReplyMessages.value = response.data?.quickReplyMessages || [];
            swal.close();
        }
        async function open(withManageParam: boolean, withSelectParam: boolean, selectedQuickReplyModeParam: QuickReplyMode) {
            selectedQuickReplyMode.value = selectedQuickReplyModeParam;
            withSelect.value = withSelectParam;
            withManage.value = withManageParam;
            await getQuickReplyMessages();
            isOpen.value = true;
        }

        function selectQuickReply(quickReplyId: string) {
            emit('onSelectQuickReply', quickReplyId);
        }

        function openQuickReplyEditor(quickReply: QuickReplyMessageViewModel | null = null) {
            modalQuickReplyEditor.value?.open(quickReply);
        }

        async function switchMode() {
            swal.showLoading();
            selectedQuickReplyMode.value =
                selectedQuickReplyMode.value == QuickReplyMode.Manage ? QuickReplyMode.Select : QuickReplyMode.Manage;
            await q.delay(400);
            swal.close();
        }

        async function close() {
            if (selectedQuickReplyMode.value == QuickReplyMode.Manage && withSelect.value) {
                switchMode();
                return;
            }
            isOpen.value = false;
        }

        function saveQuickReply(quickReplyMessageParam: QuickReplyMessageViewModel) {
            const quickReplyMessage = quickReplyMessages.value.find(x => x.id == quickReplyMessageParam.id);
            if (quickReplyMessage) {
                quickReplyMessage.leadTypeId = quickReplyMessageParam.leadTypeId;
                quickReplyMessage.leadTypeName = quickReplyMessageParam.leadTypeName;
                quickReplyMessage.message = quickReplyMessageParam.message;
            } else {
                quickReplyMessages.value.unshift(quickReplyMessageParam);
            }
        }

        async function deleteQuickReplyMessage(quickReplyMessageId: string) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('alert.delete-quick-reply.confirm'),
                customClass: {
                    confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-4',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('alert.yesDeleteIt'),
                cancelButtonText: t('button.cancel')
            });
            if (!swalResult.isConfirmed) {
                return;
            }
            await q.delay(200);
            swal.showLoading();
            const response = await api.deleteQuickReplyMessage(quickReplyMessageId);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            const indexToDelete = quickReplyMessages.value.findIndex(x => x.id == quickReplyMessageId);
            quickReplyMessages.value.splice(indexToDelete, 1);
            swal.close();
        }
        return {
            isOpen,
            open,
            close,
            quickReplyMessages,
            selectQuickReply,
            openQuickReplyEditor,
            modalQuickReplyEditor,
            saveQuickReply,
            switchMode,
            deleteQuickReplyMessage,
            withManage,
            QuickReplyMode,
            selectedQuickReplyMode
        };
    }
});
